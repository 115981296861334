<template>
  <default-layout>
    <v-container fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <v-row>
        <v-col cols="6">
          <div class="d-flex flex-wrap">
            <detail
              :detail="detail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></detail>
            <client-detail
              :detail="detail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></client-detail>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="d-flex flex-wrap">
            <assigned-agent-detail
              :detail="detail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></assigned-agent-detail>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const Detail = () => import('@/components/transaction-management/lead/detail');
const ClientDetail = () => import('@/components/transaction-management/lead/client-detail');
const AssignedAgentDetail = () =>
  import('@/components/transaction-management/lead/assigned-agent-detail');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    Detail,
    ClientDetail,
    AssignedAgentDetail,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.lead.permissions,
      allowedActions: (state) => state.lead.allowedActions,
      detail: (state) => state.lead.detail,
    }),
  },
  async mounted() {
    await this.loadDetail();
  },
  methods: {
    async refreshAction() {
      await this.loadDetail();
    },
    async loadDetail() {
      try {
        this.isLoading = true;
        this.$store.commit('lead/RESET_DEFAULT');
        const response = await this.$store.dispatch(
          'lead/getDetailInitData',
          this.$route.params.uuid,
        );
        console.log('DETAIL LEAD: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
